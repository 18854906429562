import { Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalStoreService} from './local-store.service';


@Injectable({
    providedIn: 'root'
})
export class DataService {
    private errorData: { errorDesc: string; errorTitle: string };

    constructor(public http: HttpClient, private store: LocalStoreService) {}

    public getData<T>(url: string) {
        return this.http.get(url).pipe(
            catchError(this.handleError)
        );
    }
    public getDataWithHeader<T>(url: string) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'x-access-token': this.getToken(),
                'platform':  'Web'
            })
        };
        return this.http.get(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }
    public postDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
              //  'content-type':'multipart/form-data',
                'x-access-token': this.getToken(),
                'platform':  'Web',
                'time': new Date().getTime().toString()
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithHeaderFormData<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                // 'Content-Type':'multipart/form-data',
                'x-access-token': this.getToken(),
                // 'platform':  'Web',
                // 'time': new Date().getTime().toString()
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public putDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'x-access-token': this.getToken(),
                'platform':  'Web',
                'time': new Date().getTime().toString()
            })
        };
        return this.http.put(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public deleteDataWithHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'x-access-token': this.getToken(),
                'platform':  'Web',
                'time': new Date().getTime().toString()
            }),
            body :toAdd,
        };
        return this.http.delete(url, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postDataWithoutHeader<T>(url: string, toAdd: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.post(url, toAdd, httpOptions).pipe(
            catchError(this.handleError)
        );
    }

    public postData<T>(url: string, toAdd: any) {
        return this.http.post(url, toAdd).pipe(
            catchError(this.handleError)
        );
    }

    getToken() {
        let  AuthToken = this.store.getItem('loggedUser');

        AuthToken = AuthToken.token;
        // console.log(AuthToken);
        return AuthToken;
    }

    getGoogleAccessToken() {
        return this.store.getItem('google_auth_access_token');
    }

    getZoomAccessToken() {
        return this.store.getItem('zoom_access_token');
    }

    getMicrosoftAccessToken() {
        return this.store.getItem('ms_access_token');
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }

        this.errorData = {
            errorTitle: 'Oops! Request for document failed',
            errorDesc: 'Something bad happened. Please try again later.'
        };
        return throwError(this.errorData);
    }
}
