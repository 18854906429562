import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import {AuthGuard} from '../@vex/services/auth.guard';
import {DocumentUploadLayoutComponent} from './document-upload-layout/document-upload-layout.component';
import {DocumentUploadGuard} from '../@vex/services/document-upload.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'lock',
    loadChildren: () => import('./pages/auth/lock-out/lock-out.module').then(m => m.LockOutModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'reset-password/:id',
    loadChildren: () => import('./pages/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
  },
  {
    path: 'document-upload',
    component: DocumentUploadLayoutComponent, canActivate: [DocumentUploadGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('../app/document-upload-layout/document-upload/document-upload.module')
            .then(m => m.DocumentUploadModule),
      },
    ]
  },
  {
    path: '',
    component: CustomLayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'how-it-works',
        loadChildren: () => import('./pages/how-it-works/how-it-works.module').then(m => m.HowItWorksModule),
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then(m => m.ChangePasswordModule),
      },
      {
        path: 'withdrawal',
        loadChildren: () => import('./pages/withdrawal/withdrawal.module').then(m => m.WithdrawalModule),
      },
      {
        path: 'commission-withdrawal',
        loadChildren: () => import('./pages/commission-withdrawal/commissionWithdrawal.module').then(m => m.commissionWithdrawalModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/admin-profile/admin-profile.module').then(m => m.AdminProfileModule),
      },
      {
        path: 'bank-detail',
        loadChildren: () => import('./pages/bank-detail/bank-detail.module').then(m => m.BankDetailModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/customer-list/customer.module').then(m => m.CustomerModule),
      },
      {
        path: 'order-commission',
        loadChildren: () => import('./pages/order-commission/order-commission.module').then(m => m.OrderCommissionModule),
      },
      {
        path: 'help',
        loadChildren: () => import('./pages/help/help.module').then(m => m.HelpModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./pages/admin-profile/admin-profile.module').then(m => m.AdminProfileModule),
      },

      {
        path: 'error-404',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module)
      },
      {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
