import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {LocalStoreService} from './local-store.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router , private store: LocalStoreService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // if (this.isLoggedIn()) {
    //   return true;
    // }
    const loggedUser = this.store.getItem('loggedUser');
    // console.log(loggedUser);

    if (loggedUser) {
      if (loggedUser.token && loggedUser.status === 1 && loggedUser.isDocumentUploaded === 1) {
        // this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        return true;
      } else if (loggedUser.token && loggedUser.status === 0) {
        this.router.navigate(['/document-upload'], { queryParams: { returnUrl: state.url }});
        return true;
      } else {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
    } else {
      this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
      return false;
    }
      // navigate to login page as user is not authenticated
    // return false;
  }

  // public isLoggedIn(): boolean {
  //   let status = false;
  //   const loggedUser = this.store.getItem('loggedUser');
  //   console.log(loggedUser);
  //
  //   if (loggedUser) {
  //     if (loggedUser.token && loggedUser.status === 1 && loggedUser.isDocumentUploaded === 1) {
  //       status = true;
  //     } else {
  //       status = false;
  //     }
  //   } else {
  //     status = false;
  //   }
  //   return status;
  // }
}
