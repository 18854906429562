import { Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../@vex/services/layout.service';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { checkRouterChildsData } from '../../@vex/utils/check-router-childs-data';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ConfigService } from '../../@vex/services/config.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../@vex/components/sidebar/sidebar.component';
import {UserIdleService} from 'angular-user-idle';
import {LocalStoreService} from '../../@vex/services/local-store.service';
import {AuthServiceLocal} from '../../@vex/services/auth.service';


@UntilDestroy()
@Component({
  selector: 'vex-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss']
})
export class CustomLayoutComponent implements OnInit {

  sidenavCollapsed$ = this.layoutService.sidenavCollapsed$;
  isFooterVisible$ = this.configService.config$.pipe(map(config => config.footer.visible));
  isDesktop$ = this.layoutService.isDesktop$;

  toolbarShadowEnabled$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    map(() => checkRouterChildsData(this.router.routerState.root.snapshot, data => data.toolbarShadowEnabled))
  );
  AdminDetail;

  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;

  constructor(private layoutService: LayoutService, private auth: AuthServiceLocal,
              private configService: ConfigService, private store: LocalStoreService,
              private breakpointObserver: BreakpointObserver,
              private router: Router, private userIdle: UserIdleService) {
    this.AdminDetail = this.auth.getUser();
  }

  ngOnInit() {
    this.checkUserInactive();

    this.layoutService.configpanelOpen$.pipe(
      untilDestroyed(this)
    ).subscribe(open => open ? this.configpanel.open() : this.configpanel.close());
  }


  /**
   * function used for automatic lockout
   */
  checkUserInactive() {
    this.userIdle.startWatching();
    // Start watch when time is up.
    this.userIdle.onTimerStart().subscribe(count => {});

    this.userIdle.onTimeout().subscribe(() => {
      const userData = {
        email: this.AdminDetail.email,
        firstName: this.AdminDetail.firstName,
        lastName: this.AdminDetail.lastName,
        token: null
      };
      this.store.setItem('loggedUser', userData);
      this.router.navigate(['/lock']);
    });
  }
}
