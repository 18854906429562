<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple>

  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
  <div class="body-1 font-medium text-capitalize leading-snug ltr:mr-3 rtl:ml-3" fxHide.xs>  {{AdminDetails.firstName}} {{AdminDetails.lastName}}  </div>
</div>
