// export const ApiEndPointUrl = 'https://apistage.phlenu.com/api/v6/';  // 'http://54.218.126.151:8080/api/v6/';
// export const ApiEndPointUrl = 'https://api5.phlenu.com/api/v6/';
// export const BankListEndPointUrl = 'https://api5.phlenu.com/api/v2/';
// export const BaseUrl = 'https://api5.phlenu.com/';
export const BaseUrl = 'https://apistage.phlenu.com/';

export enum Pattern {
    Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
    Mobile = '[0-9]+',
    MobileMinLengthForGhana = 9,
    MobileMinLengthForNigeria = 10,
    Password = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$',
    facebookProfile = '(?:https?:\\/\\/)?(?:www\\.)?facebook\\.com\\/(?:(?:\\w)*#!\\/)?(?:pages\\/)?(?:[\\w\\-]*\\/)*([\\w\\-\\.]*)?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?:?(www)?instagram\\.com\\/([A-Za-z0-9-_]+)?\\S?(\\/)?',
    // instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(\\/\\?[a-z]{2}=[a-z]{2})',
    instagramProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:instagram\\.com)\\/([A-Za-z0-9-_\\.@]+)?(?:(\\/\\?[a-z]{2}=[a-z]{2})|\\/|)',
    youTubeChannel = '((http|https):\\/\\/|)(www\\.|)youtube\\.com\\/(channel\\/|user\\/)[a-zA-Z0-9\\-]{1,}([\\w\\-\\_]*)?(\\/)?',
    twitterProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:twitter\\.com)\\/([A-Za-z0-9-_\\.]+)',
    tiktokProfile = '(?:(?:http|https):\\/\\/)?(?:www\\.)?(?:tiktok\\.com)\\/([A-Za-z0-9-_\\.@]+)',
    helpMessageMaxLength = 2000
}


export enum country {
    Ghana = 'ghana',
    Nigeria = 'nigeria',
    US = 'us'
}


export enum countryParams {
    Ghana = 1,
    Nigeria = 2,
    US = 3
}

export enum currency {
    Ghana = 'GHS',
    Nigeria = 'NGN',
    US = 'USD'
}

export enum countryCode {
    Ghana = '+233',
    Nigeria = '+234',
    US = '+1'
}

export enum status {
    Pending = 0,
    Approved = 1,
}
