import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from './layout/layout.module';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig} from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    imports: [
        CommonModule,
        LayoutModule,
        MatDialogModule,
        MatButtonModule,
        MatSnackBarModule
    ],
  exports: [
    LayoutModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
          duration: 3000,
          panelClass: ['snackbar-dark'],
          horizontalPosition: 'right'
      } as MatSnackBarConfig
    },
      CookieService
  ],
  declarations: [ConfirmationDialogComponent]
})
export class VexModule {
}
